import styled from 'styled-components';
import { colors } from '../../../helpers/appColors';

const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 40px 20px 150px;
  
  .block-souscription{
    border-bottom : solid 1px ${colors.primaryGreen} ;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  h2{
    font-weight: bold;
    color: ${colors.textGrey};
    span{
      color: ${colors.primaryGreen};
    }
  }
  
  h3{
    text-align: center;
    font-size: 32px;
    margin-top: 40px;
    color: ${colors.textGrey};
  }
  
  h4{
    color: white;
    font-size: 22px;
  }
  
  #pricing-table{
    font-size: 0.9em;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
    overflow-x: auto;
  }
  
  #pricing-table table tbody tr td {
      height: 50px;
  }
  
  #pricing-table table th, #pricing-table table td {
    border-left: solid white 10px;
    border-bottom: solid 1px white;
    padding: 5px;
  }
  
  .pricing-column{
    vertical-align: text-top;
    padding: 10px !important;
    width: 20%;
    text-align: center;
    border-bottom: 5px solid white !important;
    font-weight: bold;
    color: ${colors.textGrey};
    strong {
      font-size: 1.5em;
      @media(max-width: 1366px){
        font-size: 1em;
        }
      span {
        margin-left: 10px;
        font-size: 2em;
        @media(max-width: 1366px){
        font-size: 1.5em;
        }
      }
    }
  }
  
  .primary-green-cell{
    background: #3db17a !important;
    color: white;
    a {
      color: white;
    }
  }
  
  .text-center{
    text-align: center;
  }
  
  .grey-cell {
    background: #E4E4E5;
    color: #515151;
  }
  
  .ant-tooltip-disabled-compatible-wrapper{
    width: 100%;
  }
  
`;

export default StyleWrapper;