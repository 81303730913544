import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import NumberFormat from 'react-number-format';
import modalActions from "../../../Redux/modals/actions";
import subscriptionActions from "../../../Redux/subscription/actions";
import StyleWrapper from './StyleWrapper';
import { colors } from '../../../helpers/appColors';
import { getVat } from '../../../helpers/userHelper';

import {Row, Col, Button, Form, Divider, Modal, Popconfirm} from 'antd';
import {FormCheckbox, FormRadio} from "../../../helpers/forms";
import _ from "lodash";
import { CreditCardOutlined } from '@ant-design/icons';
import moment from 'moment';


class CustomerSubscription extends Component{

  state = {
    selectedOffer: null,
    totalWithoutTaxes: 0,
    vat: 0,
    vatRate: 0.2,
    showSuccess: false,
    showError: false
  };

  formRef = React.createRef();

  onFinish = (values) => {
    const {subscriptionType} = values;
    this.props.createSubscriptionCheckoutSession(subscriptionType);
  };

  onValuesChange = (changedValue, allValues) => {
    const { vatRate } = this.state;
    const { pricing } = this.props;
    if(allValues.subscriptionType){
      this.setState({
        selectedOffer: allValues.subscriptionType,
        totalWithoutTaxes: pricing[allValues.subscriptionType],
        vat: pricing[allValues.subscriptionType] * vatRate
      });
    }
  };

  customSubmit = (submitType) => {
    this.formRef.current.setFieldsValue({
      submitType: submitType
    });

    this.formRef.current.submit();
  };


  componentDidMount(){
    const queryParams = new URLSearchParams(window.location.search);
    const error = queryParams.get('error');
    const hash = queryParams.get('hash');

    const {vat, user} = this.props;
    if(user && vat){
      this.setState({
        vatRate: !user.sameInvoicingCoordinates ? getVat(user.billingRegion, vat)-1 : getVat(user.region, vat)-1
      });
    }

    if(error){
      this.setState({showError: true});
    }
    if(hash){
      this.setState({showSuccess: true});
    }
  }


  render(){
    const { user, pricing } = this.props;
    if(!user || !pricing) return (<></>);

    const cgu = _.find(this.props.documents, ['name', 'cgu']);

    return (
      <StyleWrapper>

        <Modal
          title="C'est parfait !"
          visible={this.state.showSuccess}
          closable={false}
          footer={<Button type='primary' href={'/'}>Retour au dashboard</Button>}
        >
          <p style={{textAlign: 'center'}}>
            Votre changement d'offre a bien été prise en compte. <br/>
            Si votre paiement a été accepté, un email de confirmation vous sera transmis
            sur votre adresse <strong>{user.email}</strong>.
          </p>
        </Modal>

        <Modal
          title="Oups !"
          visible={this.state.showError}
          closable={true}
          onCancel={() => this.setState({showError: false})}
          footer={null}
        >
          <p style={{textAlign: 'center'}}>
            Il semble que le paiement n'a pas abouti. <br/>
            Veuillez utiliser un autre mode de paiement ou réessayer plus tard.
          </p>
        </Modal>

        <Form onFinish={this.onFinish} onValuesChange={this.onValuesChange} ref={this.formRef}>
          <Form.Item name={'submitType'} hidden={true}/>
          <Row gutter={16} style={{justifyContent: 'center',width: '95%',marginLeft: '85px'}}>
            <Col span={24} className="shadowed-block">
              <div  className='block-souscription'>
                <div>
                  <h2>Ma formule</h2>
                </div>
                <div>
                <p><strong>Statut :</strong> {user.subscriptionType } <a href='https://billing.stripe.com/p/login/cN24iqer993h6SAdQQ' target='_blank' title='Modifier mon moyen de paiement' style={{fontSize: 12, marginLeft: 10}}><CreditCardOutlined /> Modifier mon moyen de paiement</a>
                  <Popconfirm placement="top" title={`Voulez-vous vraiment résilier votre abonnement ?`} onConfirm={()=> this.props.cancelSubscription()} okText="Oui" cancelText="Non">
                    {" "}<Button style={{marginLeft: 10}} type='button' disabled={!(user.subscriptionType !== 'Free' && !user.subscriptionCanceledAt)}>Résilier mon abonnement</Button>
                  </Popconfirm>
                  
                  { (user.subscriptionType !== 'Free') ? <strong style={{marginLeft:"10px"}}>Prochaine échéance :</strong>  : ''}
                  {
                  user.subscriptionEndAt && !user.subscriptionCanceledAt ? moment(user.subscriptionEndAt).format('DD/MM/YYYY') : ''}
                  {user.subscriptionCanceledAt ? <em style={{color: colors.primaryRed}}>Résilié - Offre active jusqu'au {moment(user.subscriptionEndAt).format('DD/MM/YYYY')}</em> : ''}
                </p>
                </div>
              </div>
              <h3>1. Modifiez votre formule</h3>
              <Row gutter={16} style={{margin: '40px 0px'}}>
                <Col span={22} offset={1}>
                  <div id="pricing-table">
                    <table>
                      <thead>
                      <tr>
                        <th colSpan="2"/>
                        <td className="pricing-column"><strong>STARTER <span>🏎</span>️</strong><br/>Idéal pour réclamer des pénalités</td>
                        <td className="pricing-column"><strong>PREMIUM <span>🚀</span></strong><br/>Utilisation régulière</td>
                        <td className="pricing-column"><strong>PRIVILEGE <span>🛰</span>️</strong><br/>Recouvrement au meilleur tarif</td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style={{borderTop: 'solid white 5px'}}>
                        <td className="primary-green-cell" colSpan="2" style={{paddingLeft: 20, fontSize: '1.3em'}}>
                          <strong>Abonnement mensuel (sans engagement)</strong>
                        </td>
                        <td className="grey-cell text-center">
                          <FormRadio style={{marginBottom: '-10px'}} options={[{value: 'monthlyStarter'}]} name='subscriptionType' rules={[{required: true, message: 'Veuillez sélectionner une offre'}]} id={'monthlyStarter'} />
                          <label htmlFor={'monthlyStarter'}>
                            <NumberFormat
                              displayType={'text'}
                              value={pricing.monthlyStarter}
                              decimalSeparator={','}
                              fixedDecimalScale
                              decimalScale={2}
                              thousandSeparator={' '}
                              suffix={'€'}
                            /> / mois</label>
                        </td>
                        <td className="grey-cell text-center">
                          <FormRadio style={{marginBottom: '-10px'}} options={[{value: 'monthlyPremium'}]} name='subscriptionType' rules={[{required: true, message: 'Veuillez sélectionner une offre'}]} id={'monthlyPremium'} />
                          <label htmlFor={'monthlyPremium'}>
                            <NumberFormat
                              displayType={'text'}
                              value={pricing.monthlyPremium}
                              decimalSeparator={','}
                              fixedDecimalScale
                              decimalScale={2}
                              thousandSeparator={' '}
                              suffix={'€'}
                            /> / mois</label>
                        </td>
                        <td className="grey-cell text-center">
                          <FormRadio style={{marginBottom: '-10px'}} options={[{value: 'monthlyPrivilege'}]} name='subscriptionType' rules={[{required: true, message: 'Veuillez sélectionner une offre'}]} id={'monthlyPrivilege'} />
                          <label htmlFor={'monthlyPrivilege'}>
                            <NumberFormat
                              displayType={'text'}
                              value={pricing.monthlyPrivilege}
                              decimalSeparator={','}
                              fixedDecimalScale
                              decimalScale={2}
                              thousandSeparator={' '}
                              suffix={'€'}
                            /> / mois</label>
                        </td>
                      </tr>
                      <tr>
                        <td className="primary-green-cell" colSpan="2" style={{paddingLeft: 20, fontSize: '1.3em'}}>
                          <strong>Abonnement annuel (économisez 2 mois)</strong>
                        </td>
                        <td className="grey-cell text-center">
                          <FormRadio style={{marginBottom: '-10px'}} options={[{value: 'yearlyStarter'}]} name='subscriptionType' rules={[{required: true, message: 'Veuillez sélectionner une offre'}]} id={'yearlyStarter'} />
                          <label htmlFor={'yearlyStarter'}>
                            <NumberFormat
                              displayType={'text'}
                              value={pricing.yearlyStarter}
                              decimalSeparator={','}
                              fixedDecimalScale
                              decimalScale={0}
                              thousandSeparator={' '}
                              suffix={'€'}
                            /> / an</label>
                        </td>
                        <td className="grey-cell text-center">
                          <FormRadio style={{marginBottom: '-10px'}} options={[{value: 'yearlyPremium'}]} name='subscriptionType' rules={[{required: true, message: 'Veuillez sélectionner une offre'}]} id={'yearlyPremium'} />
                          <label htmlFor={'yearlyPremium'}>
                            <NumberFormat
                              displayType={'text'}
                              value={pricing.yearlyPremium}
                              decimalSeparator={','}
                              fixedDecimalScale
                              decimalScale={0}
                              thousandSeparator={' '}
                              suffix={'€'}
                            /> / an</label>
                        </td>
                        <td className="grey-cell text-center">
                          <FormRadio style={{marginBottom: '-10px'}} options={[{value: 'yearlyPrivilege'}]} name='subscriptionType' rules={[{required: true, message: 'Veuillez sélectionner une offre'}]} id={'yearlyPrivilege'} />
                          <label htmlFor={'yearlyPrivilege'}>
                            <NumberFormat
                              displayType={'text'}
                              value={pricing.yearlyPrivilege}
                              decimalSeparator={','}
                              fixedDecimalScale
                              decimalScale={0}
                              thousandSeparator={' '}
                              suffix={'€'}
                            /> / an</label>
                        </td>
                      </tr>
                      <tr >
                        <th colSpan="2"/>
                        <th className='text-center' style={{padding: 0, borderTop: 'solid 5px white'}}>
                          <Button type={'primary'} style={{width: '100%', borderRadius: 0}} onClick={() => this.props.showModal('customerPricing')}>
                            Détail
                          </Button>
                        </th>
                        <th className='text-center' style={{padding: 0, borderTop: 'solid 5px white'}}>
                          <Button type={'primary'} style={{width: '100%', borderRadius: 0}} onClick={() => this.props.showModal('customerPricing')}>
                            Détail
                          </Button>
                        </th>
                        <th className='text-center' style={{padding: 0, borderTop: 'solid 5px white'}}>
                          <Button type={'primary'} style={{width: '100%', borderRadius: 0}} onClick={() => this.props.showModal('customerPricing')}>
                            Détail
                          </Button>
                        </th>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col span={24} className="shadowed-block" style={{background: colors.primaryGreen}}>
              <Row gutter={16}>
                <Col span={18} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', color: 'white', fontSize: '1.3em'}}>
                  <strong>Total HT :</strong>
                  <strong>TVA :</strong>
                  <h4>Total de la commande :</h4>
                </Col>
                <Col span={5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', color: 'white', fontSize: '1.3em'}}>
                  <strong>
                    <NumberFormat
                      displayType={'text'}
                      value={this.state.totalWithoutTaxes}
                      decimalSeparator={','}
                      fixedDecimalScale
                      decimalScale={2}
                      thousandSeparator={' '}
                      suffix={'€ HT'}
                    />
                  </strong>
                  <strong>
                    <NumberFormat
                      displayType={'text'}
                      value={this.state.vat}
                      decimalSeparator={','}
                      fixedDecimalScale
                      decimalScale={2}
                      thousandSeparator={' '}
                      suffix={'€'}
                    />
                  </strong>
                  <h4>
                    <NumberFormat
                      displayType={'text'}
                      value={this.state.totalWithoutTaxes + this.state.vat}
                      decimalSeparator={','}
                      fixedDecimalScale
                      decimalScale={2}
                      thousandSeparator={' '}
                      suffix={'€ TTC'}
                    />
                  </h4>
                </Col>
                <Col span={18} style={{textAlign: 'right'}}>
                  <FormCheckbox name='cguAccetped' rules={[{required: true, message:'Veuillez accepter les CGU', type: 'enum', enum:[true]}]} id='cguAccetped' preserve={true} label={(<strong style={{color: 'white'}}>J'accepte les <a href={cgu.url} target='_blank' rel="noreferrer" style={{color: '#b3ffdb'}}>conditions générales d'utilisation</a></strong>)} />
                </Col>
              </Row>
            </Col>

            <Col span={24} className="shadowed-block">

              <h3>2. Validez le paiement de votre choix</h3>
              <Divider/>

              <Row gutter={16} style={{margin: 0, paddingTop: 30}}>
                <Col span={12} offset={6} style={{textAlign: 'center', paddingBottom: 50}}>
                  <Button
                    onClick={() => this.customSubmit('credit_card')}
                    type={'default'}
                    key={'submitType'}
                    value='credit_card'
                    size='large'
                    loading={this.props.subscriptionLoading}
                    style={{background: colors.primaryOrange, color: 'white', border: colors.primaryOrange, width: '100%'}}
                  >
                    Paiement par CB ou prélèvement SEPA
                  </Button>
                  <br/>
                  <br/>
                  <strong>Powered by Stripe </strong>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </StyleWrapper>
    )
  }
}


export default withRouter(connect(
  state => ({
    user: state.user.info,
    pricing: state.app.prices.subscriptionPricing,
    vat: state.app.prices.vat,
    subscriptionLoading: state.subscription.isLoading,
    documents: state.app.documents
  }),
  {
    createSubscriptionCheckoutSession: subscriptionActions.reqCreateSubscriptionCheckoutSession,
    showModal: modalActions.showModal,
    cancelSubscription: subscriptionActions.cancelSubscription
  }
)(CustomerSubscription));